import React, { useState, useCallback } from 'react'

import { useSwipeable, SwipeEventData } from 'react-swipeable'

import { isMobile } from '@interco/lib-util'

import * as S from './styles'

export type BottomSheetProps = {
  onClose: () => void
  children: React.ReactNode | string
  noSwipe?: boolean
  fixedHeight?: string
}

const PIXELS_DOWN_TO_CLOSE = 100

export const BottomSheet = ({ children, onClose, noSwipe, fixedHeight }: BottomSheetProps) => {
  const [toDown, setToDown] = useState<number>(0)
  const [applyCloseAnimation, setApplyCloseAnimation] = useState<boolean>(false)

  const shouldClose = useCallback(
    ({ dir, deltaY }: SwipeEventData, finished = false) => {
      if (!noSwipe) {
        if (dir !== 'Down') return

        setToDown(deltaY)

        if (!finished) return

        const shouldBeClose = deltaY > PIXELS_DOWN_TO_CLOSE
        if (shouldBeClose) {
          setApplyCloseAnimation(true)
          setTimeout(onClose, S.ANIMATION_TIME)
          return
        }

        setToDown(0)
      }
    },
    [onClose, setToDown, noSwipe],
  )

  const { ref, ...handlers } = useSwipeable({
    onSwiping: shouldClose,
    onSwiped: (eventData) => shouldClose(eventData, true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: !isMobile(),
    trackTouch: isMobile(),
    delta: 10,
  })

  return (
    <S.Overlay
      data-testid="bottom-sheet-overlay"
      onClick={(event) => {
        event.stopPropagation()
        event.target === event.currentTarget && onClose()
      }}
    >
      <S.GlobalStyle />
      <S.Container
        {...handlers}
        ref={(reference: HTMLDivElement) => {
          if (!reference || noSwipe) return
          ref(reference)
        }}
        down={toDown}
        fixedHeight={fixedHeight}
        closeAnimation={applyCloseAnimation}
        data-testid="bottom-sheet-container"
      >
        <S.HandlerContainer>
          <S.Handler />
        </S.HandlerContainer>

        <S.Content>{children}</S.Content>
      </S.Container>
    </S.Overlay>
  )
}
